<template>
  <div class="data-auth-apply">
    <span>{{title}}</span>
    <span>。</span>
    <template v-if="applyStatus === 0">
      <span>您的申请正在审核中</span>
    </template>
    <template v-else-if="applyStatus === -1 || applyStatus === 3">
      <span>可点击<a href="javascript:void(0)" @click="applyDataAuth">这儿</a>申请</span>
    </template>
    <template v-else-if="applyStatus === 2">
      <span>您的申请已被拒绝，可点击<a href="javascript:void(0)" @click="applyDataAuth">这儿</a>重新申请</span>
    </template>
  </div>
</template>

<script>
import {applyDataAuth, getDataAuthApplyStatus} from "@/api/dataAuthApply";

export default {
  name: "dataAuthApply",
  data() {
    return {
      applyStatus: -2, //-2未知(默认)，-1未申请，0-申请中，1-通过，2-拒绝，3-收回
    }
  },
  props: {
    title: {
      type: String,
      default: '暂无权限查看数据'
    },
    info: {
      type: Object,
      default: () => {
        return {
          event: null,
          target: null,
          targetId: null
        }
      }
    }
  },
  mounted() {
    this.getDataAuthApplyStatus();
  },
  watch: {
    info: {
      handler(newVal, oldVal) {
        if (!newVal) return;
        this.getDataAuthApplyStatus();
      },
      deep: true
    }
  },
  methods: {
    getDataAuthApplyStatus() {
      let params = this.info || {};

      if (!params.event || !params.target || !params.targetId) {
        console.error(`获取权限状态时缺少参数: params = ${JSON.stringify(params)}`);
        return;
      }

      getDataAuthApplyStatus(params).then(res => {
        if (res.success) {
          let data = res.data || {};
          this.applyStatus = data.status != null ? data.status : -1;
          console.log('applyStatus', params.event, this.applyStatus);
        }
      })
    },

    applyDataAuth() {
      let params = this.info || {};

      if (!params.event || !params.target || !params.targetId) {
        console.error(`申请权限时缺少参数: params = ${JSON.stringify(params)}`);
        return;
      }

      applyDataAuth(params).then(res => {
        if (res.success) {
          this.applyStatus = 0;
          this.$message({
            type: 'success',
            message: '申请成功，审批通过后即可查看。'
          });
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.data-auth-apply {
  text-align: center;
  font-size: 14px;
  color: #666;

  a {
    color: #2d8cf0;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
