/*
 * @Description: 
 * @Author: transparent
 * @Date: 2022-02-14 14:29:37
 * @LastEditTime: 2022-02-14 14:30:59
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/api/systemOperationManual.js
 */
import request from '@/utils/request';

//评论与回复列表
export function getdingTalksystemUserManual(param) {
    return request({
        url: '/admin/ding-talk/drive/system-user-manual',
        method: 'post',
        data: param
    })
}