<!--
 * @Description: 系统操作指南
 * @Author: transparent
 * @Date: 2022-02-14 14:25:10
 * @LastEditTime: 2022-03-21 14:22:58
 * @LastEditors: transparent
 * @FilePath: /hermes-web/src/views/systemOperationManual.vue
-->

<template>
  <el-container>
    <el-aside class="scrollbarX">
      <!-- <el-input placeholder="输入关键字进行过滤" v-model="filterText">
      </el-input> -->
      <el-tree
        highlight-current
        class="filter-tree"
        :data="projecWorkflowTree"
        :props="defaultProps"
        :default-expand-all="false"
        :filter-node-method="filterNode"
        ref="tree"
        @node-click="handleNodeClick"
      >
        <span class="custom-tree-node" slot-scope="{ node }">
          <span class="beyondDisplay2 spanlable">{{ node.label }}</span>
        </span>
      </el-tree>
    </el-aside>
    <el-main class="documentList scrollbarX" v-loading="OnlinePreviewLoading">
      <el-tabs
        v-model="activeName"
        :tab-position="tabPosition"
        activate-name=""
      >
        <el-tab-pane label="文件" name="first">
          <template v-if="dataAuth.dingTalkFile === true">
            <el-row type="flex" class="row-bg" justify="end">
              <div class="importTemplateInput">
                <el-button
                  size="mini"
                  type="primary"
                  @click="refreshFileShowType"
                  icon="el-icon-refresh"
                  >{{
                    documentDisplayType ? "切换网格展示" : "切换列表展示"
                  }}</el-button
                >
              </div>
            </el-row>
            <el-row type="flex" class="row-bg" justify="center">
              <div class="importTemplateInput">
                <el-button
                  type="primary"
                  style="margin-left: 0px"
                  @click="goBindDingTalk()"
                  v-show="goBindDingTalkShow"
                  >绑定钉钉</el-button
                >
              </div>
            </el-row>
            <div class="breadcrumb" v-show="breadcrumbList.length > 1">
              <div
                class="breadcrumb-item"
                v-for="(item, index) in breadcrumbList"
                :key="index"
                @click="breadcrumbOne(item, index)"
              >
                <span class="beyondDisplay1">{{ item.fileName }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="documentDisplayType1" v-if="documentDisplayType">
              <el-table
                class="table2"
                row-key="fileId"
                style="width: 100%"
                :data="dingTalkFileList"
                highlight-current-row
                @current-change="handleCurrentChange"
              >
                <el-table-column label="文件名" prop="fileName" width="400">
                  <template slot-scope="scope">
                    <p>
                      <i
                        v-if="scope.row.fileType == 'folder'"
                        class="el-icon-folder-opened fileType"
                      ></i>
                      <i v-else class="el-icon-document fileType"></i>

                      <span class="fileName">{{ scope.row.fileName }}</span>
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  label="是否文件夹"
                  prop="folder"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.fileType == "folder" ? "是" : "否"
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="文档类型"
                  prop="fileExtension"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.fileExtension }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="scope">
                    <el-button
                      type="primary"
                      size="mini"
                      v-show="scope.row.fileType != 'folder'"
                      @click="dingFilePreview(scope.row)"
                      >预览</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              class="documentDisplayType2 flexFwAc"
              v-else
              v-show="dingTalkFileList"
            >
              <div
                class="one flexFJA"
                v-for="(item, index) in dingTalkFileList"
                :key="index"
                @click="documentDisplayType2One(item)"
              >
                <div class="top">
                  <div class="folder" v-if="item.fileType == 'folder'">
                    <svg class="icon" aria-hidden="true">
                      <use :xlink:href="iconType(item.fileType).class"></use>
                    </svg>
                  </div>
                  <div class="file" v-else>
                    <svg
                      class="icon"
                      aria-hidden="true"
                      v-if="fileTypes.indexOf(item.fileExtension) != '-1'"
                    >
                      <use
                        :xlink:href="iconType(item.fileExtension).class"
                      ></use>
                    </svg>
                    <svg class="icon" aria-hidden="true" v-else>
                      <use :xlink:href="iconType('file').class"></use>
                    </svg>
                  </div>
                </div>
                <div class="bottom beyondDisplayNo">
                  <span> {{ item.fileName }}</span>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="dataAuth.dingTalkFile === false">
            <el-empty :image-size="50">
              <template slot="description">
                <data-auth-apply :info="dataAuthInfo" />
              </template>
            </el-empty>
          </template>
        </el-tab-pane>
      </el-tabs>
    </el-main>
    <el-dialog
      title="PDF预览"
      center
      :visible.sync="dialogVisible"
      @close="closePdf"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
    >
      <div class="pdfUrlF scrollbar" v-if="pdfUrl"></div>
      <div class="imgUrl" v-if="imgUrl">
        <el-image ref="imgUrl" :src="imgUrl">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePdf">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 文件预览 -->
    <el-dialog
      title="文件预览"
      center
      :visible.sync="previewDialogVisible"
      @close="closePreviewDialog"
      :close-on-click-modal="false"
      style="margin-top: -5vh"
      width="70%"
    >
      <div class="imgUrl" v-if="previewImgUrl">
        <el-image
          style="width: 100%; height: auto"
          :src="previewImgUrl"
          :preview-src-list="[]"
        >
        </el-image>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closePreviewDialog">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import { getdingTalksystemUserManual } from "@/api/systemOperationManual";
import {
  getTaskFileList,
  getDingTalkFileList,
  dingTalkFilePreview,
} from "@/api/projectManage";
import { formatDate } from "@/common/date";
import pdf from "@/common/pdf/pdf";
import { unity, fileTypes } from "@/common/iconType.js";
import DataAuthApply from "@/components/dataAuth/dataAuthApply";
export default {
  name: "systemOperationManual",
  data() {
    return {
      projecWorkflowTree: null,
      filterText: null,
      defaultProps: {
        children: "children",
        label: "fileName",
      },
      taskFileList: null,
      dingTalkFileList: null,
      dialogVisible: false,
      pdfUrl: null,
      numPages: 1,
      width: 100,
      pages: 0,
      imgUrl: null,
      scale: 100,
      tabPosition: "top",
      breadcrumbList: [
        {
          id: null,
          fileName: "全部",
          fileType: "folder",
        },
      ],
      currentRow: null,
      chooseProjectId: null,
      chooseProject: null,
      goBindDingTalkShow: false,
      activeName: "first",
      documentDisplayType: false,
      iconType: unity.setIconType,
      fileTypes: fileTypes,
      dataAuth: {
        documentFile: null,
        dingTalkFile: null,
      },
      dataAuthInfo: {
        event: null,
        target: "t_project",
        targetId: null,
        ability: 10,
      },
      OnlinePreviewLoading: false,
      previewDialogVisible: false,
      previewImgUrl: null,
    };
  },
  filters: {
    formatDateFilter(time) {
      var date = new Date(time);
      return formatDate(date, "yyyy-MM-dd hh:mm:ss");
    },
  },
  props: {
    tabIndex: String,
  },
  components: {
    DataAuthApply,
    pdf,
  },
  created() {},
  activated() {
    this.getdingTalksystemUserManual({}, 1);
  },
  mounted() {
    this.dataAuthInfo.event = "ding_file";
    this.breadcrumbList = [
      {
        id: null,
        fileName: "全部",
        fileType: "folder",
      },
    ];
    this.getDingTalkFileList(this.chooseProject);
  },
  methods: {
    getFileUrl(file) {
      this.OnlinePreviewLoading = true;
      console.log(file);
      dingTalkFilePreview({
        fileId: file.fileId,
        fileName: encodeURI(file.fileName),
        spaceType: "90",
      }).then((response) => {
        if (response.success) {
          let data = response.data;
          // console.log(data.downloadUri);
          this.openOnlinePreviewFile(file, data.downloadUri);
          this.OnlinePreviewLoading = false;
        } else {
          this.$message({
            type: "info",
            message: response.message,
          });
        }
      });
    },
    openOnlinePreviewFile(file, downloadUri) {
      console.log(file);
      let fileType = file.fileExtension;
      this.$router.push({
          name: "officeOnLine",
          params: { file: file, url: downloadUri,fileType:fileType },
        });
        return false;
      if (
        fileType == "docx" ||
        fileType == "doc" ||
        fileType == "xlsx" ||
        fileType == "xls" ||
        fileType == "ppt" ||
        fileType == "pptx"
      ) {
        // let url = encodeURIComponent(downloadUri);
        window.open(
          "https://view.officeapps.live.com/op/view.aspx?src=" +
            encodeURI(downloadUri)
        );
      } else if (fileType == "pdf") {
        let origin = window.location.origin;
        let url = origin + "/pdf/web/viewer.html?file=" + downloadUri;
        window.open(encodeURI(url));
      } else if (
        fileType == "jpg" ||
        fileType == "jpeg" ||
        fileType == "png" ||
        fileType == "gif"
      ) {
        this.previewImgUrl = downloadUri;
        this.previewDialogVisible = true;
      } else {
        this.$message({ type: "warning", message: "请下载后查看!" });
        window.open(encodeURI(downloadUri));
      }
    },
    closePreviewDialog() {
      this.previewImgUrl = null;
      this.previewDialogVisible = false;
    },

    refreshFileShowType() {
      this.documentDisplayType = !this.documentDisplayType;
    },
    /*
     * 加载PDF文件
     * url：后台提供的pdf地址或者项目中public/pdf文件（test.pdf）
     * 在需要的位置调用 this.loadFile(url)
     * */
    loadFile(url) {
      PDF.getDocument(url).then((pdf) => {
        this.pdfUrl = pdf;
        this.pages = this.pdfUrl.numPages;
        this.$nextTick(() => {
          this.renderPage(1);
        });
      });
    },
    /*
     * 渲染PDF文件
     * */
    renderPage(num) {
      this.pdfUrl.getPage(num).then((page) => {
        let canvas = document.getElementById("canvas" + num);
        let ctx = canvas.getContext("2d");
        let dpr = window.devicePixelRatio || 1; // 设备像素比
        let bsr =
          ctx.webkitBackingStorePixelRatio ||
          ctx.mozBackingStorePixelRatio ||
          ctx.msBackingStorePixelRatio ||
          ctx.oBackingStorePixelRatio ||
          ctx.backingStorePixelRatio ||
          1; // 浏览器在渲染canvas之前会用几个像素来来存储画布信息,类似img
        let ratio = dpr / bsr;
        let viewport = page.getViewport(1.5);
        canvas.width = viewport.width * ratio; // 画布大小,默认值是width:300px,height:150px
        canvas.height = viewport.height * ratio;
        canvas.style.width = viewport.width + "px"; // 画布的框架大小
        canvas.style.height = viewport.height + "px";
        let renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };
        page.render(renderContext);
        if (this.pages > num) {
          this.renderPage(num + 1);
        } else {
          // this.closeServerLoadingHandle()
        }
      });
    },
    goBindDingTalk() {
      this.$router.push({
        path: "/personalCenter",
        query: {
          type: "bindDingTalk",
        },
      });
      this.goBindDingTalkShow = false;
    },
    documentDisplayType2One(val) {
      console.log("ding file preview1", val);
      if (val.fileType != "folder") {
        this.getFileUrl(val);
        return false;
      } else {
        let data2 = { parentId: val.fileId, nextToken: "", maxResults: 50 };
        this.breadcrumbList.push(val);
        this.getdingTalksystemUserManual(data2, 2);
      }
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      console.log("ding file preview2", val);
      if (val.fileType != "folder") {
        this.getFileUrl(val);
        return false;
      } else {
        let data2 = { parentId: val.fileId, nextToken: "", maxResults: 50 };
        this.breadcrumbList.push(val);
        this.getdingTalksystemUserManual(data2, 2);
      }
    },
    dingFilePreview(val) {
      console.log("ding file preview3", val);
      if (val.fileType != "folder") {
        this.getFileUrl(val);
      }
    },
    breadcrumbOne(val, index) {
      const index1 = index;
      // console.log(val.fileType);
      // console.log(index1);
      // console.log(this.breadcrumbList.length);
      if (val.fileType != "folder") {
        return false;
      }
      let breadcrumbIndex = index1 + 1;
      let breadcrumbsLength = this.breadcrumbList.length;
      if (breadcrumbIndex != breadcrumbsLength) {
        console.log("不是最后一级");
        let data = { parentId: val.fileId, nextToken: "", maxResults: 50 };
        if (index1 >= 0) {
          let spliceIndex = index1 + 1;
          console.log(spliceIndex);
          this.breadcrumbList.splice(spliceIndex);
        }
        this.getdingTalksystemUserManual(data, 2);
      } else {
        console.log("最后一级");
      }
    },
    breadcrumbClick(row) {
      console.log(row);
    },
    handleCurrentChangeFirst(val) {
      console.log("1212");
      console.log(val);
      this.handleExport("2", val);
    },
    handleExport(type, item) {
      if (!item) return false;
      if (type == 1) {
        //下载文件
        var elemIF = document.createElement("iframe");
        elemIF.src = item.url;
        elemIF.style.display = "none";
        document.body.appendChild(elemIF);
      } else if (type == 2) {
        let fileType = item.url.split(".")[item.url.split(".").length - 1];
        console.log(item);
        this.$router.push({
          name: "officeOnLine",
          params: { file: item, url: item.url,fileType:fileType },
        });
        return false;
        if (
          fileType == "docx" ||
          fileType == "doc" ||
          fileType == "xlsx" ||
          fileType == "xls" ||
          fileType == "ppt" ||
          fileType == "pptx"
        ) {
          console.log(item.url);
          let url = encodeURIComponent(item.url);
          window.open(
            "https://view.officeapps.live.com/op/view.aspx?src=" + url
          );
        } else if (fileType == "pdf") {
          console.log(item.url);
          this.imgUrl = null;
          console.log(window.location.origin);
          let origin = window.location.origin;
          let url = origin + "/pdf/web/viewer.html?file=" + item.url;
          window.open(url);

          // this.pdfUrl = item.url;
          // this.getNumPages(item.url);
          // this.dialogVisible = true;
          // this.loadFile(item.url);
        } else if (
          fileType == "jpg" ||
          fileType == "jpeg" ||
          fileType == "png" ||
          fileType == "gif"
        ) {
          this.pdfUrl = null;
          this.imgUrl = item.url;
          this.dialogVisible = true;
        } else {
          this.$message({ type: "warning", message: "请下载后查看!" });
          window.open(item.url);
        }
      }
    },
    getNumPages(url) {
      var that = this;
      var loadingTask = pdf.createLoadingTask(url);
      loadingTask.promise
        .then((pdf) => {
          that.pdfUrl = loadingTask;
          that.numPages = pdf.numPages;
          that.dialogVisible = true;

          // setTimeout(() => {
          // }, 2000);
        })
        .catch((err) => {
          console.log(err);
          console.error("pdf加载失败");
        });
    },
    closePdf() {
      this.pdfUrl = null;
      this.imgUrl = null;
      this.numPages = 1;
      this.dialogVisible = false;
    },

    getDingTalkFileList(data) {
      if (!data) return;
      this.dataAuth.dingTalkFile = null;
      let info = {
        projectId: data.projectId,
        parentId: data.parentId || "",
        nextToken: data.nextToken || "",
        maxResults: data.maxResults || "",
      };
      getDingTalkFileList(info)
        .then((response) => {
          if (response.success) {
            if (response.data.files == null) {
              this.dingTalkFileList = [];
              this.dataAuth.dingTalkFile = false;
            } else {
              this.dingTalkFileList = response.data.files;
              this.dataAuth.dingTalkFile = true;
            }
          } else {
            if (response.message == "请先绑定钉钉！") {
              this.goBindDingTalkShow = true;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (e == "请先绑定钉钉！") {
            this.goBindDingTalkShow = true;
          }
        });
    },
    getTaskFileList(data) {
      this.dataAuth.documentFile = null;
      let info = {
        projectWorkflowId: data.projectWorkflowId,
        projectId: data.projectId,
      };
      getTaskFileList(info).then((response) => {
        if (response.success) {
          if (response.data == null) {
            this.taskFileList = [];
            this.dataAuth.documentFile = false;
          } else {
            this.taskFileList = response.data;
            this.dataAuth.documentFile = true;
          }
        }
      });
    },
    handleNodeClick(data) {
      console.log(data);
      if (data.fileType == "folder") {
        this.dingTalkFileList = [];
        this.dataAuthInfo.event = "ding_file";
        // this.dataAuthInfo.targetId = this.chooseProjectId;
        this.breadcrumbList = [
          {
            fileId: data.fileId,
            fileName: data.fileName,
            fileType: "folder",
          },
        ];
        this.getdingTalksystemUserManual(
          { parentId: data.fileId, nextToken: "", maxResults: 50 },
          2
        );
      } else if (data.fileType == "file") {
        this.dingTalkFileList = [];
        this.getFileUrl(data);

      }
    },
    getdingTalksystemUserManual(data, type) {
      // orderType
      if (type == 1) {
        data = { ...data, orderType: "nameAsc" };
      } else {
      }
      getdingTalksystemUserManual(data)
        .then((response) => {
          if (response.success) {
            if (response.data.files == null) {
              this.dingTalkFileList = [];
              this.dataAuth.dingTalkFile = false;
            } else {
              this.dataAuth.dingTalkFile = true;
              if (type == 1) {
                this.projecWorkflowTree = response.data.files;
              } else {
                this.dingTalkFileList = response.data.files;
              }
            }
          } else {
            if (response.message == "请先绑定钉钉！") {
              this.goBindDingTalkShow = true;
            }
          }
        })
        .catch((e) => {
          console.log(e);
          if (e == "请先绑定钉钉！") {
            this.goBindDingTalkShow = true;
          }
        });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.s-bg2 {
  background-color: rgb(161, 95, 95) !important;
}
.s-c {
  color: blue !important;
}
.el-container {
  width: 100%;
  height: calc(100vh - 12rem);
  .el-aside {
    width: 30% !important;
    overflow: scroll;
    .el-tree {
      width: 100% !important;
      .spanlable {
      }
    }
  }
  .el-main {
  }
  .documentList {
    padding: 15px !important;
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
}
.pdfUrlF {
  text-align: center;
  // height: 20%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .pdfUrl {
    width: 100%;
    text-align: center;
    // display: table-cell;
    background: transparent;
  }
}

.imgUrl {
  width: 100%;
  text-align: center;
}
/* 点击树结构项的选中颜色 */
::v-deep .el-tree {
  background-color: #fff;
}
::v-deep .el-tree-node__content {
  &:hover {
    background-color: #ccc;
  }
}
::v-deep .el-tree-node.is-current > .el-tree-node__content {
  background-color: #999 !important;
  color: #fff;
}
.table2 {
  .fileName {
    margin-left: 5px;
  }
  .fileType {
    font-weight: bold !important;
    font-size: 16px;
  }
}
::v-deep .el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.breadcrumb {
  font-size: 14px;
  line-height: 1;
  .breadcrumb-item {
    float: left;
    cursor: pointer;
    span {
      color: #303133;
    }
    i {
      margin: 0 6px;
      font-weight: bold !important;
      color: #c0c4cc;
    }
  }
  .breadcrumb-item:last-child {
    i {
      display: none;
    }
    span {
      color: #606266;
    }
  }
}
.beyondDisplay1 {
  max-width: 150px;
}
.pdf-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.documentDisplayType2 {
  align-items: baseline;
  .one {
    width: 120px;
    margin: 10px;
    cursor: pointer;
    .top {
      height: 40px;
    }
    .bottom {
      max-width: 100px;
      font-size: 14px;
    }
  }
}
</style>
