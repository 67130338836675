import request from '@/utils/request';

//申请权限
export function applyDataAuth(params){
  return request({
    url: '/admin/data-auth-apply/apply',
    method: 'post',
    data: params
  })
}

//获取权限申请详情
export function getDataAuthApplyDetail(id) {
  return request({
    url: '/admin/data-auth-apply/detail' + id,
    method: 'get'
  })
}

//获取权限申请状态
export function getDataAuthApplyStatus(params) {
  return request({
    url: '/admin/data-auth-apply/status',
    method: 'post',
    data: params
  })
}

//获取数据权限申请列表
export function getDataAuthApplyList(params) {
  return request({
    url: '/admin/data-auth-apply/list',
    method: 'post',
    data: params
  })
}

//审批权限
export function approveDataAuth(params) {
  return request({
    url: '/admin/data-auth-apply/approve',
    method: 'post',
    data: params
  })
}

//撤销权限
export function revokeDataAuth(params) {
  return request({
    url: '/admin/data-auth-apply/revoke',
    method: 'post',
    data: params
  })
}
